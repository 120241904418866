<template>
  <div class="artice_top widthCen content">
    <h6 @click="$router.back(-1)" style="cursor: pointer;">
      学车视频 > {{model.TypeStr}} >
      <span>考前准备</span>
    </h6>
    <div class="article_white">
      <div class="details">
        <h6>{{model.Title}}</h6>
        <!-- <p>
          类型:
          <span>{{TypeStr}}</span>
        </p> -->
        <!-- <div class="details_box">
          <img src="../../images/home_banner.png" />
        </div>-->
        <div class="details_text" v-html="model.Content"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/public.scss";
@import "@/styles/artice.scss";
</style>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      model: {
        Title: '',
        Content: '',
        TypeStr: ''
      }
    };
  },
  created() {
    var id = this.$route.query.id;
    this.getInfo(id);
  },
  methods: {
    getInfo(id) {
      this.$api.getTipInfo(id).then(res => {
        this.model = res.data;
      });
    }
  }
};
</script>
